import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import BarcodeReaderView from '@/views/BarcodeReaderView.vue'
import ItemSearchView from '@/views/ItemSearchView.vue'
import ForbiddenView from '@/views/ForbiddenView.vue'
import authMiddleware from '@/middlewares/authMiddleware'
import roleMiddleware from '@/middlewares/roleMiddleware'
import warehouseMiddleware from '@/middlewares/warehouseMiddleware'
import guestMiddleware from '@/middlewares/guestMiddleware'
import onEachRoute from '@/resources/modules/onEachRoute'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomeView,
		beforeEnter: [guestMiddleware],
		meta: {
			title: "home.meta.title",
			metaTags: [
				{
					name: 'description',
					content: "home.meta.description"
				}
			]
		}
	},
	{
		path: '/recupera-password',
		name: 'PasswordRecover',
		beforeEnter: [guestMiddleware],
		meta: {
			title: "auth.password_recover.meta.title",
		}
	},

	// MAGAZZINIERI

	// scelta magazzino di lavoro
	{
		path: '/magazzinieri/scegli-magazzino',
		name: 'MagazzinieriWarehousePick',
		component: () => import(/* webpackChunkName: "magazzinieri" */ '../views/Magazzinieri/WarehousePickView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware],
	},

	{
        // elenco richieste
		path: '/magazzinieri/richieste',
		name: 'MagazzinieriIndex',
		component: () => import(/* webpackChunkName: "magazzinieri" */ '../views/Magazzinieri/IndexView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// dettaglio richiesta
		path: '/magazzinieri/richieste/:id',
		name: 'MagazzinieriRequestDetail',
		component: () => import(/* webpackChunkName: "magazzinieri-richiesta" */ '../views/Magazzinieri/RequestDetailView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.detail.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// aggiungi articolo
		path: '/magazzinieri/richieste/:id/aggiungi-articolo',
		name: 'MagazzinieriAddItem',
		component: () => import(/* webpackChunkName: "magazzinieri-aggiungi-elemento" */ '../views/Magazzinieri/ItemAddView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// dettaglio articolo
		path: '/magazzinieri/richieste/:id/articolo/:id_articolo',
		name: 'MagazzinieriItemDetail',
		component: () => import(/* webpackChunkName: "magazzinieri-aggiungi-elemento" */ '../views/Magazzinieri/ItemDetailView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// nuovo inventario
		path: '/magazzinieri/inventario/nuovo',
		name: 'MagazzinieriInventoryCreate',
		component: () => import(/* webpackChunkName: "magazzinieri-crea-inventario" */ '../views/Magazzinieri/InventoryCreateView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "inventory.current.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// aggiungi articolo all'inventario
		path: '/magazzinieri/inventario/:id/aggiungi-articolo',
		name: 'MagazzinieriInventoryAddItem',
		component: () => import(/* webpackChunkName: "magazzinieri-inventario-aggiungi-elemento" */ '../views/Magazzinieri/InventoryItemAddView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "inventory.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// aggiungi dispositivi di protezione individuale
		path: '/magazzinieri/richieste/:id/aggiungi-DPI',
		name: 'MagazzinieriAdditemDPI',
		component: () => import(/* webpackChunkName: "magazziniere-aggiungi-DPI" */ '../views/Magazzinieri/MagazzinieriAdditemDPI.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],
	},
	{
		// aggiungi dispositivi di protezione individuale
		path: '/magazzinieri/richieste/:id/consegna-KIT',
		name: 'MagazzinieriConsegnaKIT',
		component: () => import(/* webpackChunkName: "magazziniere-aggiungi-DPI" */ '../views/Magazzinieri/MagazzinieriConsegnaKIT.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],

	},
	{
		// alloca DPI
		path: '/magazzinieri/richieste/:id/alloca-DPI',
		name: 'MagazzinieriAllocaDPI',
		component: () => import(/* webpackChunkName: "magazziniere-alloca-DPI" */ '../views/Magazzinieri/MagazzinieriAllocaDPI.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 1,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware,warehouseMiddleware],

	},

	// CAPOCANTIERE
	// elenco richieste
	{
		path: '/capicantiere/richieste',
		name: 'CapicantiereRequestIndex',
		component: () => import(/* webpackChunkName: "capicantiere" */ '../views/Capicantiere/RequestIndexView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware],
	},
	{
		path: '/capicantiere/richiesta-aperta',
		name: 'CapicantiereRequestCurrent',
		component: () => import(/* webpackChunkName: "capicantiere" */ '../views/Capicantiere/RequestCurrentView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.curren.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware],
	},
	{
		// dettaglio richiesta
		path: '/capicantiere/richieste/:id',
		name: 'CapicantiereRequestDetail',
		component: () => import(/* webpackChunkName: "capicantiere-richiesta" */ '../views/Capicantiere/RequestDetailView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.detail.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware],
	},
	{
		// aggiungi articolo
		path: '/capicantiere/richieste/:id/aggiungi-articolo',
		name: 'CapicantiereAddItem',
		component: () => import(/* webpackChunkName: "capicantiere-aggiungi-elemento" */ '../views/Capicantiere/ItemAddView.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware]

	},

	{
		// aggiungi dispositivi di protezione individuale
		path: '/capicantiere/richieste/:id/aggiungi-DPI',
		name: 'AdditemDPI',
		component: () => import(/* webpackChunkName: "capicantiere-aggiungi-DPI" */ '../views/Capicantiere/AdditemDPI.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware]

	},

	{
		// aggiungi dispositivi di protezione individuale
		path: '/capicantiere/richieste/:id/consegna-KIT',
		name: 'ConsegnaKIT',
		component: () => import(/* webpackChunkName: "capicantiere-aggiungi-DPI" */ '../views/Capicantiere/ConsegnaKIT.vue'),
		meta: {
			requiresAuth: true,
			requiresRole: 2,
			title: "request.add_item.meta.title",
		},
		beforeEnter: [authMiddleware,roleMiddleware]

	},

	// Utente
	{
		path: '/profilo',
		name: 'UserProfile',
		component: () => import(/* webpackChunkName: "profilo" */ '../views/User/ProfileView.vue'),
		meta: {
			requiresAuth: true
		}
	},

	// Utente
	{
		path: '/modifica-password',
		name: 'UserPasswordChange',
		component: () => import(/* webpackChunkName: "profilo" */ '../views/User/PasswordChange.vue'),
		meta: {
			requiresAuth: true
		}
	},

	// ricerca
	{
		path: '/cerca',
		name: 'ItemSearch',
		component: ItemSearchView
	},

	// barcode reader
	{
		path: '/lettore',
		name: 'BarcodeReader',
		component: BarcodeReaderView
	},

	// forbidden
	{
		path: '/non-autorizzato',
		name: 'Forbidden',
		component: ForbiddenView
	},

	// catch all 404
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: NotFoundView
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(onEachRoute);

export default router
