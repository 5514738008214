<template>
<div id="wrapper">
	<NavApp />
	<MenuApp />

	<router-view />

	<FooterApp />
</div>
</template>

<script>
import NavApp from "@/components/NavApp.vue";
import MenuApp from "@/components/MenuApp.vue";
import FooterApp from "@/components/FooterApp.vue";

export default {
	name: "App",
	components: {
		NavApp,
		MenuApp,
		FooterApp,
	}
};
</script>

<style lang="scss">
@import "./resources/sass/main.scss";
</style>
