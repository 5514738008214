/**
 * Rimuovo i magazzini dal localstorage (al logout)
 */
export function deleteWarehouses(){
    localStorage.removeItem('Warehouses');
}


/**
 * Rimuovo il magazzino corrente dal localstorage (al logout)
 */
export function deleteCurrentWarehouse(){
    localStorage.removeItem('currentWarehouse');
}


/**
 * Ottengo l'elenco magazzi da localstorage (al login vengono salvati, al logout cancellati)
 * 
 * @returns object
 */
export async function getWarehouses(){
    try {        
        if(localStorage.getItem('Warehouses')){
            return JSON.parse(localStorage.getItem('Warehouses'));
        }
        const Warehouses = await fetch(process.env.VUE_APP_API_BASE_URL+"/it/magazzini")
                                .then((res) => res.json())
                                .then((data) => { return data });
        localStorage.setItem('Warehouses', JSON.stringify(Warehouses));
        return Warehouses;
    } catch (error) {
        console.debug(error.message);
        return [];
    }
}


/**
 * Recupero il magazzino in cui si è registrato il magazziniere
 * 
 * @returns object
 */
export async function getCurrentWarehouse(){
    try {        
        return localStorage.getItem('currentWarehouse')
                ? localStorage.getItem('currentWarehouse')
                : null;
    } catch (error) {
        console.debug(error.message);
        return null;
    }
}


/**
 * Setting current working warehouse
 * @param {string} code 
 * @returns {boolean} success
 */
export async function setCurrentWarehouse(code){
    try {
        localStorage.setItem('currentWarehouse', code);
        return true;
    } catch(e){
        console.debug(e);
        return false;
    }
}

/**
 * Ottengo il nome del magazzino in base al codice
 * @param {string} code 
 * @returns {string} se trovo il magazzino allora nome, altrimenti il codice
 */
export async function getWarehouseName(code){
    const warehouse = await getWarehouses()
                                .then(Warehouses => Warehouses.find(wh => wh.codice == code));
    return warehouse ? warehouse.title : code;
}
