import { defineStore } from "pinia";
import { i18n,router } from "@/main.js";
import { deleteWarehouses, deleteCurrentWarehouse } from "@/resources/modules/Warehouses.js"
import requestsManager from '@/resources/modules/requests_managers/requestsManager.js'
import { getWorkerId, deleteWorkers } from "@/resources/modules/Workers"

export const useAuthStore = defineStore("user", {
    id: 'user',

    state: () => ({
        user: null,
        storageKey: 'user',
        Warehouses: [],
        apiManager: () => {
            return new requestsManager(i18n);
        }
    }),

    getters: {
        isAuthenticated() {
            return !!this.user;
        },
        getRole() {
            return this.user ? this.user.ruolo : null;
        },
        isMagazziniere() {
            return this.user && this.user.ruolo === 1;
        },
        isCapocantiere() {
            return this.user && this.user.ruolo === 2;
        },
    },

    actions: {
        async login(email, password) {
            const res = await fetch(process.env.VUE_APP_API_BASE_URL+"/it/login", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: "username="+email+"&password="+password,
            });
            if (!res.ok) {
                throw new Error(i18n.global.t("auth.login_error"));
            }
            const data = await res.json();

            if(Object.prototype.hasOwnProperty.call(data, 'error') && !!data.error){
                throw data.error+' <br> '+i18n.global.t('errors.code',{'code': data.errno});
            }
            
            this.user = {
                nome: data[0].nome,
                cognome: data[0].cognome,
                email: data[0].email,
                mobile: data[0].mobile,
                ruolo: parseInt(data[0].ruolo,10),
                ruolo_as_text: null,
                designazione: data[0].designazione,
                display_name: data[0].nome+' '+data[0].cognome.substring(0,1)+'.',
            };

            this.user.id = await getWorkerId(this.user.email);

            // salvataggio delle credenziali nel browser
            if (window.PasswordCredential) {
                try {
                    const passwordCredential = new window.PasswordCredential({ id: email, password: password, name:  this.user.display_name});
                    navigator.credentials.store(passwordCredential);
                } catch(e){
                    console.warning(e);
                }
            }

            // aggiornamento api manager dopo login
            this.apiManager = new requestsManager(i18n);


            /**
             * Definisco la pagina di default dell'utente in base al ruolo
             */
            switch (this.user.ruolo) {
                case 1:
                    this.user.ruolo_as_text = "Magazziniere";
                    this.user.default_route = {name: "MagazzinieriIndex"}
                    break;
                case 2:
                    this.user.ruolo_as_text = "Capocantiere";
                    this.user.default_route = {name: 'CapicantiereRequestCurrent'}
                    break;
                default:
                    this.user.ruolo_as_text = "Utente";
                    this.user.default_route = {name: 'UserProfile'}
                }

            /**
             * Salvo le informazioni dell'utente nel local storage
             */
            localStorage.setItem(this.storageKey, JSON.stringify(this.user));

            /**
             * Vado alla pagina di default dell'utente
             */
            if(router.currentRoute.value.name !== this.user.default_route.name){
                router.push(this.user.default_route);
            }
        },
        logout() {
            // deleting the user will automatically be synchronized with other tabs
            localStorage.removeItem(this.storageKey);

            deleteWarehouses();
            deleteCurrentWarehouse();
            deleteWorkers();

            this.user = null;
            this.Warehouses = [];

            this.apiManager = () => {
                return new requestsManager(i18n);
            };

            // redirect solo se current route è diversa da login o da home
            if(router.currentRoute.value.name !== 'Home' && router.currentRoute.value.path !== '/'){
                router.push({name: 'Home'});
            }
        }
    },
});