import { useAuthStore } from '@/stores/user'

const guestMiddleware = (to, from, next) => {
    const authStore = useAuthStore()
    // Verifica se l'utente è autenticato
    if (authStore.isAuthenticated) {
        const user = authStore.user;
        // Reindirizza l'utente alla pagina di login
        try {
            next({
                name: user.default_route.name
            })
        } catch(error) {
            authStore.logout()
        }
    } else {
        // L'utente è autenticato, continua con la navigazione
        next()
    }
}

export default guestMiddleware