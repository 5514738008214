<template>
	<div id="footer" class="uk-text-center uk-text-small uk-text-muted">
		{{
		t("footer.sede_legale", {
			address: "Via Ruggero Bacone, 4, 09134 Cagliari",
		})
		}}
		- {{ t("footer.footer_piva", { value: "02589650924" }) }} - CCIAA di
		Cagliari - Nr. 209743
	</div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
	name: "FooterApp",
	setup() {
		const { t } = useI18n();

		return {
			t,
		};
	}
};
</script>
