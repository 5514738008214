<template>
	<div>
		<h1 id="page_title">Ricerca</h1>
		<div class="uk-marging">
		<form @submit.prevent="handleSearch">
			<div class="uk-margin">
				<div class="uk-grid uk-grid-small" uk-grid>
					<div class="uk-form-controls uk-width-expand@s">
						<input
						class="uk-input"
						id="item_name"
						type="text"
						:placeholder="t('search.placeholder')"
						v-model="search"
						required
						/>
					</div>
					<div class="uk-width-1-2 uk-width-auto@s">
						<button class="uk-button uk-button-primary">
						<span uk-icon="icon: search"></span>
						</button>
					</div>
					<div class="uk-width-1-2 uk-width-auto@s">
						<button class="uk-button uk-button-secondary" type="button" tabindex="-1" @click="showBarcodeReader">
						<span uk-icon="icon: camera"></span>
						</button>
					</div>
				</div>
			</div>
		</form>
		</div>
		<div class="uk-margin-large-top" v-if="useScanner">
			<BarcodeReaderView />
		</div>
		<div class="uk-margin-large-top">
			<h5>{{t('search.results_title')}}</h5>
			<table class="uk-table">
				<legend></legend>
				<thead>
					<tr>
					<th>Nome</th>
					<th>Codice</th>
					<th>Immagine</th>
					</tr>
				</thead>
				<tbody v-if="items.length && search.length">
					<tr v-for="item in items" :key="item.id">
						<td>{{ item.title }}</td>
						<td>{{ item.codice }}</td>
						<td>
							<img :src="item.foto" alt="item.title" width="100" />
						</td>
					</tr>
				</tbody>
				<tbody v-else-if="!items.length && search.length">
					<tr><td colspan="4">{{t('search.no_results')}}</td></tr>
				</tbody>
				<tbody v-else>
					<tr><td colspan="4">{{t('search.start_search')}}</td></tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref, reactive, provide } from "vue";
import { useI18n } from "vue-i18n";
import BarcodeReaderView from './BarcodeReaderView.vue'

export default {
	name: "ItemSearch",
	components: {
		BarcodeReaderView
	},
	setup() {
		const code = reactive({
			search: ''
		});
		const items = ref("");
		const useScanner = ref(false);
		const { t } = useI18n();

		const handleSearch = () => {
			fetch(process.env.VUE_APP_API_BASE_URL+"/it/articoli?search=" + code.search)
				.then((response) => response.json())
				.then((data) => {
				items.value = data;
			});
		};

		const showBarcodeReader = () => {
			useScanner.value = true;
		};

		provide('search', code)

		return {
		search: code.search,
		handleSearch,
		t,
		items,
		useScanner,
		showBarcodeReader
		};
	}
};
</script>