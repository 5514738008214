import { useAuthStore } from '@/stores/user'
import UIkit from 'uikit'
import { i18n } from '@/main'

const roleMiddleware = (to, from, next) => {
    const authStore = useAuthStore()
    if (to.matched.some(record => record.meta.requiresRole)) {
        // Verifica se l'utente è autenticato
        const meta = to.matched[to.matched.length - 1].meta;
        if (!meta.requiresRole || meta.requiresRole !== authStore.getRole) {
            UIkit.notification({
                message: i18n.global.t("auth.role_required"),
				status: "danger",
				pos: "top-right",
				timeout: 8000,
            });

            // Reindirizza l'utente alla pagina forbidden
            next({
                name: 'Forbidden'
            })
        } else {
            // L'utente è autenticato, continua con la navigazione
            next()
        }
    } else {
        // La route non richiede l'autenticazione, continua con la navigazione
        next()
    }
}

export default roleMiddleware