<template>
  <div class="not_found uk-text-center">
    <div class="uk-container uk-container-xsmall">
      <h1 class="uk-heading-divider">404</h1>
      <h3>{{ t("notfound.title") }}</h3>
      <div v-html="t('notfound.text')"></div>
      <p class="uk-margin-large-top">
        <router-link
          class="uk-button uk-button-secondary"
          :to="route"
          >{{ t("notfound.back_to_home") }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useAuthStore } from "@/stores/user";

export default {
	setup() {
		const { t } = useI18n();
    const authStore = useAuthStore();
    const route = computed(() => { return authStore.isAuthenticated ? authStore.user.default_route : {name: 'Home'}; });

		return {
			t,
      route,
		};
	}
};
</script>
