<template>
<nav
	id="header"
	class="uk-navbar-container uk-navbar-transparent"
	uk-navbar
	uk-sticky="show-on-up: true"
>
	<div class="uk-navbar-left">
		<h1 class="uk-margin-remove uk-logo">
			<router-link :to="{ name: 'Home' }">
			<img id="logo" src="@/assets/logo.png" alt="SELT" />
			</router-link>
		</h1>
    </div>
    <div class="uk-navbar-right">
		<ul class="uk-navbar-nav">
			<li class="uk-visible@m" v-if="isAuthenticated">
			<router-link
				:to="{ name: 'UserProfile' }"
				class="uk-padding-remove-right"
				><span class="text-label" style="width: auto">{{
				t("nav.ciao", { name: user.display_name })
				}}</span></router-link
			>
			</li>
			<li class="uk-hidden@m" v-if="isAuthenticated">
			<router-link
				:to="{ name: 'UserProfile' }"
				class="uk-padding-remove-right"
				><span uk-icon="user"></span></router-link
			>
			</li>
			<li v-if="isAuthenticated"><a @click="logout"><span uk-icon="sign-out"></span></a></li>
			<li v-if="isAuthenticated">
				<a href="#mobile-menu" class="uk-padding-remove-right" uk-toggle>
					<span uk-icon="menu"></span>
				</a>
			</li>
			<li v-if="!isAuthenticated"><a>&nbsp;</a></li>
		</ul>
    </div>
</nav>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/user";
import { useRouter } from "vue-router";
import Notifier from '@/resources/modules/Notifier';

export default {
	name: "NavApp",
	setup() {
		const authStore = useAuthStore();
		const user = computed(() => { return authStore.user; });
		const isAuthenticated = computed(() => { return authStore.isAuthenticated; });

		const router = useRouter();
		const { t } = useI18n();

		const logout = () => {
			authStore.logout();

			Notifier.success(t('nav.logout_success'),{
				timeout: 3000
			});

			router.push({ name: "Home" });
		};


		return {
			t,
			user,
			logout,
			isAuthenticated
		};
	}
};
</script>
