/**
 * Rimuovo i lavoratori dal localstorage (al logout)
 */
export function deleteWorkers(){
    localStorage.removeItem('Workers');
}


/**
 * Ottengo l'elenco magazzi da localstorage (al login vengono salvati, al logout cancellati)
 * 
 * @returns object
 */
export async function getWorkers(){
    try {        
        if(localStorage.getItem('Workers')){
            return JSON.parse(localStorage.getItem('Workers'));
        }
        const Workers = await fetch(process.env.VUE_APP_API_BASE_URL+"/it/lavoratori")
                                .then((res) => res.json())
                                .then((data) => { return data });
        localStorage.setItem('Workers', JSON.stringify(Workers));
        return Workers;
    } catch (error) {
        console.debug(error.message);
        return [];
    }
}


/**
 * Ottengo il nome del lavoratore in base all'id
 * @param {int} id 
 * @returns {string} se trovo il lavoratore allora nome, altrimenti id
 */
export async function getWorkerName(id){
    const Worker = await getWorkers()
                                .then(Workers => Workers.find(wh => wh.codice == id));
    return Worker ? Worker.nome+' '+Worker.cognome : id;
}


/**
 * Ottengo l'id del lavoratore in base alla mail
 * @param {string} email
 * @returns {int} se trovo il lavoratore allora id, altrimenti null
 */
export async function getWorkerId(email){
    const Worker = await getWorkers()
                                .then(Workers => Workers.find(wh => wh.email == email));
    return Worker ? Worker.codice : null;
}
