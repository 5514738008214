import { getCurrentWarehouse } from "@/resources/modules/Warehouses.js"

const warehouseMiddleware = async (to, from, next) => {
    const currentWarehouse = await getCurrentWarehouse();

    if(currentWarehouse){
        next();
    } else {
        next({
            name: 'MagazzinieriWarehousePick'
        })
        // next();
    }
}

export default warehouseMiddleware