import forbidden from '../lang/403.json'
import notfound from '../lang/404.json'
import footer from '../lang/footer.json'
import home from '../lang/home-view.json'
import nav from '../lang/nav.json'
import auth from '../lang/auth.json'
import errors from '../lang/errors.json'
import profile from '../lang/profile.json'
import request from '../lang/request.json'
import search from '../lang/search.json'
import inventory from '../lang/inventory.json'
import form from '../lang/form.json'

export default function loadTrads(locale) {

    const sources = {
        forbidden,
        notfound,
        footer,
        home,
        nav,
        auth,
        errors,
        profile,
        search,
        request,
        inventory,
        form
    }
    const messages = {};

    for (const [key, value] of Object.entries(sources)) {
        try {
            if(!messages[locale]){
                messages[locale] = {};
            }
            messages[locale][key] = value[locale];
        } catch(e){
            console.warn(e);
            messages[locale][key] = value['it'];
        }
    }

    return messages
}