<template>
  <div class="forbidden uk-text-center">
    <div class="uk-container uk-container-xsmall">
      <h1 class="uk-heading-divider">403</h1>
      <h3>{{ t("forbidden.title") }}</h3>
      <div v-html="t('forbidden.text')"></div>
      <p class="uk-margin-large-top">
        <router-link
          class="uk-button uk-button-secondary"
          :to="route"
          >{{ t("forbidden.back_to_home") }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useAuthStore } from "@/stores/user";

export default {
	setup() {
		const { t } = useI18n();
    const authStore = useAuthStore();
    const route = computed(() => { return authStore.isAuthenticated ? authStore.user.default_route : {name: 'Home'}; });

		return {
			t,
      route
		};
	}
};
</script>
