// import UIkit from "uikit";
import Notifier from '@/resources/modules/Notifier';
import { useAuthStore } from "@/stores/user";
import { getCurrentWarehouse, getWarehouseName } from '@/resources/modules/Warehouses';
import { getWorkerName } from "@/resources/modules/Workers"

export default class requestsManager {

    constructor(i18n){
        this.translator = i18n.global;
        this.base_url = process.env.VUE_APP_API_BASE_URL;
        this.api_url = this.base_url+"/it";
        this.authStore = useAuthStore();

        // a seconda di cosa sto cercando cambia l'endpoint
        this.searchModes = [
            {
                value: 'search',
                label: this.translator.t('search.by_string')
            },
            {
                value: 'id_field',
                label: this.translator.t('search.by_id_field')
            },
            {
                value: 'id_asset',
                label: this.translator.t('search.by_id_asset')
            },
        ];
    }


    /**
     * Ottengo le richieste; posso impersonare un altro ruolo
     * @param {int} pretendedRole
     */
    async getRequests(pretendedRole){
        const role = pretendedRole || this.authStore.getRole;
        switch(role){
            case 1: // magazziniere
                return await this.getRequestsAsWarehouseWorker();
            case 2: // capocantiere
                return await this.getRequestsAsSiteManager();
            default:
                throw new Error('Metodo getRequests inesistente per questo ruolo '+this.authStore.getRole);
        }
    }
    

    /**
     * Getting a single request
     * @param {int} requestId 
     * @param {int} pretendedRole
     * @returns 
     */
    async getRequest(requestId, pretendedRole){
        const role = pretendedRole || this.authStore.getRole;
        try {
            const allRequests = await this.getRequests(role);
            const request = allRequests.filter((item) => item.idRichiesta == requestId);
            return request.length ? request[0] : null;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Getting articles from a request
     * @param {int} requestId
     * @param {int} pretendedRole
     * @returns
     * @throws {Error} if request id is not provided
     */
    async getArticlesInRequest(requestId, pretendedRole){
        const role = pretendedRole || this.authStore.getRole;
        switch(role){
            case 1: // magazziniere
                return await this.getArticlesInRequestAsWarehouseWorker(requestId);
            case 2: // capocantiere
                return await this.getArticlesInRequestAsSiteManager(requestId);
            default:
                throw new Error('Metodo getArticlesInRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Getting a single article from a request
     * @param {int} requestId 
     * @param {int} articleId 
     * @returns 
     */
    async getArticleInRequest(requestId,articleId){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.getArticleInRequestAsWarehouseWorker(requestId,articleId);
            case 2: // capocantiere
            default:
                throw new Error('Metodo getArticleInRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Getting assets from an article in a request
     * @param {int} requestId 
     * @param {string} articleId 
     */
    async getAssetsInRequestByArticle(requestId,articleId){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.getAssetsInRequestByArticleAsWarehouseWorker(requestId,articleId);
            case 2: // capocantiere
            default:
                throw new Error('Metodo getAssetsInRequestByArticle inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Aggiunto un elemento alla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async addItemToRequest(requestId, articleId, quantity){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.addItemToRequestAsWarehouseWorker(requestId, articleId, quantity);
            case 2: // capocantiere
                return await this.addItemToRequestAsSiteManager(requestId, articleId, quantity);
            default:
                throw new Error('Metodo addItemToRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Rimuovo un elemento dalla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async removeItemFromRequest(requestId, articleId, quantity){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.removeItemFromRequestAsWarehouseWorker(requestId, articleId, quantity);
            case 2: // capocantiere
                return await this.removeItemFromRequestAsSiteManager(requestId, articleId, quantity);
            default:
                throw new Error('Metodo removeItemFromRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Adding an asset to a request
     * @param {int} requestId 
     * @param {string} articleId 
     * @param {string} assetCode 
     * @param {string} WarehouseId
     * @returns 
     */
    async addAssetToRequest(requestId,articleId,assetCode,WarehouseId){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.addAssetToRequestAsWarehouseWorker(requestId,articleId,assetCode,WarehouseId);
            case 2: // capocantiere
            default:
                throw new Error('Metodo addAssetToRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * removing an asset from a request
     * @param {int} requestId 
     * @param {string} articleId 
     * @param {string} assetId 
     * @returns 
     */
    async removeAssetFromRequest(requestId,articleId,assetId){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.removeAssetFromRequestAsWarehouseWorker(requestId,articleId,assetId);
            case 2: // capocantiere
            default:
                throw new Error('Metodo removeAssetFromRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Aggiorno la quantità di un consumabile in una richiesta
     * 
     * @param {int} quantity: se maggiore di 0 aggiunge, se minore di 0 rimuove
     * @param {int} requestId: id della richiesta
     * @param {string} articleCode: codice articolo
     * @returns {object} response
     */
    async updateConsommableQuantity(quantity, requestId, articleCode){
        switch(this.authStore.getRole){
            case 1: // magazziniere
                return await this.updateConsommableQuantityAsWarehouseWorker(quantity, requestId, articleCode);
            case 2: // capocantiere
            default:
                throw new Error('Metodo updateConsommableQuantity inesistente per questo ruolo '+this.authStore.getRole);
        }        
    }

    /**
     * Mappo le richieste ed inserisco eventuali dati aggiuntivi; posso impersonare un altro ruolo
     * @param {*} requests 
     * @param {int} pretendedRole
     */
    async parseRequests(requests, pretendedRole){
        const role = pretendedRole || this.authStore.getRole;
        switch(role){
            case 1: // magazziniere
                return await this.parseRequestsAsWarehouseWorker(requests);
            case 2: // capocantiere
                return await this.parseRequestsAsSiteManager(requests);
            default:
                throw new Error('Metodo parseRequests inesistente per questo ruolo '+this.authStore.getRole);
        }
    }

    /**
     * Parsing a single request
     * @param {*} request 
     * @param {int} pretendedRole
     * @returns 
     */
    async parseRequest(request, pretendedRole){
        const role = pretendedRole || this.authStore.getRole;
        switch(role){
            case 1: // magazziniere
                return await this.parseRequestAsWarehouseWorker(request);
            case 2: // capocantiere
                return request;
            default:
                throw new Error('Metodo parseRequest inesistente per questo ruolo '+this.authStore.getRole);
        }
    }


    /**
     * Invio la richiesta
     * @param {int} id della richiesta
     * @param {int} id del cantiere
     */
    async sendRequest(requestId,worksiteId){
        switch(this.authStore.getRole){
            case 2: // capocantiere
                return await this.sendRequestAsSiteManager(requestId,worksiteId);
            case 1: // magazziniere
            default:
                throw new Error('Metodo sendRequest inesistente per questo ruolo '+this.authStore.getRole);
        }

    }


    /**
     * Searching for items
     * @param {*} searchString 
     * @param {string} mode search|id_field; il primo cerca per nome, il secondo per codice
     */
    async search(searchString, mode = 'search'){
        try {
            const filteredMode = this.searchModes.forEach(item => {
                if(item.value == mode){
                    return item.value;
                }
            });
            mode = filteredMode ? filteredMode : mode;

            var response = [];
            switch(mode){
                case 'id_asset':
                    response = await this.searchAssets(searchString);
                    break;
                default:
                    response = await this.searchArticles(searchString, mode);
            }

            return response;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Searching for articles
     * @param {string} searchString 
     * @param {string} mode 
     * @returns 
     */
    async searchArticles(searchString, mode = 'search'){
        const res = await fetch(this.api_url+"/articoli?"+mode+"=" + searchString,{
            credentials: "include",
        })
        if (!res.ok) {
            throw new Error(this.translator.t("auth.login_error"));
        }

        const data = await res.json().then((data) => {
            return data;
        });

        this.manageError(data);

        return data.map((item) => {
            item.foto = Object.prototype.hasOwnProperty.call(item, 'foto') && !!item.foto ? this.base_url + item.foto : '/img/no-image.jpg';
            return item;
        });
    }


    /**
     * Searching for assets
     * @param {string} searchString 
     * @returns 
     */
    async searchAssets(searchString){
        const res = await fetch(this.api_url+"/asset?id_field=" + searchString,{
            credentials: "include",
        })
        if (!res.ok) {
            throw new Error(this.translator.t("auth.login_error"));
        }

        const data = await res.json().then((data) => {
            return data;
        });

        if(Object.prototype.hasOwnProperty.call(data, 'error') && !!data.error){
            throw new Error(data.error + ' <br>' + this.translator.t('errors.code',{'code': data.errno}));
        }

        return data;
    }


    /**
     * Ottengo i magazzini disponibili
     * @returns {array} Elenco dei magazzini
     */
    async getWarehouses(){
        try {
            const res = await fetch(this.api_url+"/magazzini/",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Ottengo l'elenco lavoratori disponibili
     * @returns {array} Elenco dei lavoratori
     */
    async getWorkers(){
        try {
            const res = await fetch(this.api_url+"/lavoratori/",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data;
        } catch (error) {
            Notifier.notify(error.message);
        }
    }


    /**
     * Gestisco gli errori nelle richieste API
     * 
     * @param {object} data 
     */
    manageError(data){
        if(!data){
            data = {
                error: this.translator.t('errors.generic'),
                errno: -1,
            }
        }
        if(Object.prototype.hasOwnProperty.call(data, 'error') && !!data.error){
            // se l'errore è -1 faccio il logout
            if(data.errno == -1 && this.authStore.isAuthenticated){
                this.authStore.logout();
            }
            throw new Error(data.error + ' <br>' + this.translator.t('errors.code',{'code': data.errno}));
        }
    }



    /*********************************************************************************
     * 
     * MAGAZZINIERI
     * 
     *********************************************************************************/
    
    /**
     * Getting all the requests
     * @returns 
     */
    async getRequestsAsWarehouseWorker(){
        try {
            const res = await fetch(this.api_url+"/packing",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data.map((item) => {
                item.dataRichiesta = item.dataRichiesta ? new Intl.DateTimeFormat('it-IT',{
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                }).format(new Date(item.dataRichiesta))
                : item.dataRichiesta;
                return item;
            });
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Getting articles from a request
     * @param {int} requestId
     * @returns
     * @throws {Error} if request id is not provided
     */
    async getArticlesInRequestAsWarehouseWorker(requestId){
        try {
            const res = await fetch(this.api_url+"/packing/"+requestId+"/",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Getting a single article from a request
     * @param {int} requestId 
     * @param {int} articleId 
     * @returns 
     */
    async getArticleInRequestAsWarehouseWorker(requestId,articleId){
        try {
            const allArticles = await this.getArticlesInRequestAsWarehouseWorker(requestId);
            const article = allArticles.filter((item) => item.codice == articleId);
            return article.length ? article[0] : null;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Getting assets from an article in a request
     * @param {int} requestId 
     * @param {string} articleId 
     */
    async getAssetsInRequestByArticleAsWarehouseWorker(requestId,articleId){
        try {
            const res = await fetch(this.api_url+"/packing/"+requestId+"/"+articleId+"/",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data.map((item) => {
                item.dataAllocazione = new Intl.DateTimeFormat('it-IT',{ 
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric'
                                        }).format(new Date(item.dataAllocazione));
                return item;
            });
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Aggiunto un elemento alla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async addItemToRequestAsWarehouseWorker(requestId, articleId, quantity){
        try {
            const res = await fetch(this.api_url+"/packing/"+requestId+"/?codice="+articleId+"&quantita="+quantity,{
                method: "PATCH",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Adding an asset to a request
     * @param {int} requestId 
     * @param {string} articleId 
     * @param {string} assetCode 
     * @param {string} WarehouseId
     * @returns 
     */
    async addAssetToRequestAsWarehouseWorker(requestId,articleId,assetCode,WarehouseId){
        try {
            // @todo WharehouseId qui mi arriva come ubicazione dell'asset: capire se va anche qui impostato sulla base dell'id magazzino dell'utente loggato
            // const WarehouseId = getCurrentWarehouse();
            const res = await fetch(this.api_url+"/packing/"+requestId+"/"+articleId+"/",{
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: "asset="+assetCode+"&idMagazzino="+WarehouseId,
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            if(Object.prototype.hasOwnProperty.call(data, 'error') && !!data.error){
                throw new Error(data.error + ' <br>' + this.translator.t('errors.code',{'code': data.errno}));
            }

            Notifier.success(this.translator.t('request.asset.added', 1));

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * removing an asset from a request
     * @param {int} requestId 
     * @param {string} articleId 
     * @param {string} assetId 
     * @returns 
     */
    async removeAssetFromRequestAsWarehouseWorker(requestId,articleId,assetId){
        try {
            const res = await fetch(this.api_url+"/packing/"+requestId+"/"+articleId+"/?asset="+assetId,{
                method: "DELETE",
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            Notifier.success(this.translator.t('request.asset.removed', data.numAssetCancellati, {n: data.numAssetCancellati}));

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Aggiorno la quantità di un consumabile in una richiesta
     * 
     * @param {int} quantity: se maggiore di 0 aggiunge, se minore di 0 rimuove
     * @param {int} requestId: id della richiesta
     * @param {string} articleCode: codice articolo
     * @returns {object} response
     */
    async updateConsommableQuantityAsWarehouseWorker(quantity, requestId, articleCode){
        try {
            const WarehouseId = await getCurrentWarehouse();
            const res = await fetch(this.api_url+"/packing/"+requestId+"/"+articleCode+"/",{
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: "qta="+quantity+"&idMagazzino="+WarehouseId,
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            Notifier.success(this.translator.t('request.item.quantity_updated', 1));

            return data;
        } catch (error) {
            Notifier.error(error.message);
        }

    }


    /**
     * Mappo le richieste ed inserisco eventuali dati aggiuntivi
     * @param {*} requests 
     */
    async parseRequestsAsWarehouseWorker(requests){
        if('undefined' != typeof requests){
            return requests.map(async (request) => {
                request.idCantiere = request.cantiere;
                request.cantiere = await getWarehouseName(request.cantiere);

                request.idAutoreRIchiesta = request.autoreRichiesta;
                request.autoreRichiesta = await getWorkerName(request.autoreRichiesta)
                return request;
            });
        }
        return [];
    }


    /**
     * Parsing a single request
     * @param {*} request 
     * @returns 
     */
    async parseRequestAsWarehouseWorker(request){
        request.idCantiere = request.cantiere;
        request.cantiere = await getWarehouseName(request.cantiere);

        request.idAutoreRIchiesta = request.autoreRichiesta;
        request.autoreRichiesta = await getWorkerName(request.autoreRichiesta)

        return request;
    }    



    /*********************************************************************************
     * 
     * CAPICANTIERE
     * 
     *********************************************************************************/

    /**
     * Ottengo le richieste di un capocantiere
     */
    async getRequestsAsSiteManager(){
        try {
            const res = await fetch(this.api_url+"/richieste",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);
            
            return [data];
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Getting articles from a request
     * @param {int} requestId
     * @returns
     * @throws {Error} if request id is not provided
     */
    async getArticlesInRequestAsSiteManager(requestId){
        try {
            const res = await fetch(this.api_url+"/richieste/"+requestId+"/",{
                credentials: "include",
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);
            
            return data;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Aggiunto un elemento alla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async addItemToRequestAsSiteManager(requestId, articleId, quantity){
        try {
            const res = await fetch(this.api_url+"/richieste/"+requestId+"/?codice="+articleId+"&quantita="+quantity,{
                method: "PATCH",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return true;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Rimuovo un elemento dalla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async removeItemFromRequestAsSiteManager(requestId, articleId){
        try {
            const res = await fetch(this.api_url+"/richieste/"+requestId+"/?codice="+articleId,{
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return true;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Rimuovo un elemento dalla richiesta
     * @param {int} requestId
     * @param {string} articleId
     * @param {int} quantity
     * @returns
     */
    async removeItemFromRequestAsWarehouseWorker(requestId, articleId){
        try {
            const res = await fetch(this.api_url+"/richieste/"+requestId+"/?codice="+articleId,{
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return true;
        } catch (error) {
            Notifier.error(error.message);
        }
    }


    /**
     * Mappo le richieste ed inserisco eventuali dati aggiuntivi
     * @param {*} requests 
     */
    parseRequestsAsSiteManager(requests){
        if('undefined' != typeof requests){
            return requests;
        }
        return [];
    }


    /**
     * Invio la richiesta
     */
    async sendRequestAsSiteManager(requestId,worksiteId){
        try {
            const res = await fetch(this.api_url+"/richieste/"+requestId+"/",{
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: "idMagazzino="+worksiteId,
            });
            if (!res.ok) {
                throw new Error(this.translator.t("auth.login_error"));
            }

            const data = await res.json().then((data) => {
                return data;
            });

            this.manageError(data);

            return true;
        } catch (error) {
            Notifier.error(error.message);
        }
    }
}