<template>
	<div>
		<h1 id="page_title">{{t('search.barcode_scanner_title')}}</h1>
		<StreamBarcodeReader
		@decode="onDecode"
		@loaded="onLoaded"
		></StreamBarcodeReader>
		<p>
			{{t('search.barcode_scanned')}}: <strong>{{ search }}</strong>
			<button class="uk-button uk-button-primary" @click="barcodeConfirm">{{t('search.confirm')}}</button>
		</p>
	</div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { useI18n } from "vue-i18n";

export default defineComponent({
	name: "BarcodeReader",
	components: {
		StreamBarcodeReader,
	},
	setup() {
		const code = inject("search");
		const { t } = useI18n();

		const onDecode = (value) => {
			code.search = value;
		};

		const onLoaded = () => {
		};

		return {
			search: code.search,
			onDecode,
			onLoaded,
			t
		};
	}
});
</script>