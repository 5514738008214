import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import loadTrads from '@/resources/modules/tradsLoader.js'
import requestsManager from '@/resources/modules/requests_managers/requestsManager.js'
import { useAuthStore } from "@/stores/user";
import 'vue-search-select/dist/VueSearchSelect.css'

const currentLocale = 'it';
const messages = loadTrads(currentLocale);

const i18n = createI18n({
    locale: currentLocale,
    fallbackLocale: "it",
    messages,
    legacy: false,
});

const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(i18n);

const authStore = useAuthStore();

// Verifica se il token è presente nel localStorage quando l'applicazione viene caricata
const localUser = localStorage.getItem(authStore.storageKey);
if (localUser) {
    authStore.user = JSON.parse(localUser);
}

const apiManager = new requestsManager(i18n);

app.provide('apiManager',apiManager);
app.provide('authStore',authStore);
app.mount('#app');

export { i18n,router };