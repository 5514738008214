<template>
<div id="mobile-menu" class="offcanvas_menu" uk-offcanvas="overlay: true; flip: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <ul class="uk-nav uk-nav-default uk-nav-divider uk-margin-large-top">
            <!-- <li class="uk-nav-header">{{t('nav.requests')}}</li> -->
            <li v-if="isAuthenticated && user.ruolo_as_text == 'Magazziniere'">
				<router-link
					:to="{ name: user.default_route.name }"
					class="uk-padding-remove-right"
					>{{t('nav.requests_list')}}</router-link>                
            </li>
			<li v-if="isAuthenticated && user.ruolo_as_text == 'Magazziniere'">
				<router-link
					:to="{ name: 'MagazzinieriWarehousePick' }"
					class="uk-padding-remove-right"
					>{{ t('nav.change_warehouse') }}</router-link>
			</li>

            <li v-if="isAuthenticated && user.ruolo_as_text == 'Capocantiere'">
				<router-link
					:to="{ name: 'CapicantiereRequestIndex' }"
					class="uk-padding-remove-right"
					>{{t('nav.requests_list')}}</router-link>                
            </li>

			<li v-if="isAuthenticated && user.ruolo_as_text == 'Magazziniere'">
				<router-link
					:to="{ name: 'MagazzinieriAdditemDPI' , params: { id: user.id } }"
					class="uk-padding-remove-right"
					>{{ t('nav.request_dpi') }}</router-link>                
            </li>

			
			<li v-if="isAuthenticated && user.ruolo_as_text == 'Magazziniere'">
				<router-link
					:to="{ name: 'MagazzinieriAllocaDPI' , params: { id: user.id } }"
					class="uk-padding-remove-right"
					>{{ t('nav.alloca_dpi') }}</router-link>
			</li>
			

			<li v-if="isAuthenticated && user.ruolo_as_text == 'Magazziniere'">
				<router-link
					:to="{ name: 'MagazzinieriConsegnaKIT' , params: { id: user.id } }"
					class="uk-padding-remove-right"
					>{{ t('nav.delivery_dpi') }}</router-link>                
            </li>

			<li v-if="isAuthenticated && user.ruolo_as_text == 'Capocantiere'">
				<router-link
					:to="{ name: 'CapicantiereRequestCurrent' }"
					class="uk-padding-remove-right"
					>{{ t('nav.current_request') }}</router-link>
			</li>


            <li v-if="isAuthenticated"><router-link
				:to="{ name: 'UserProfile' }"
				class="uk-padding-remove-right"
				>{{t('nav.your_profile')}}</router-link></li>
            <li v-if="isAuthenticated"><a @click="logout">{{t('nav.logout')}}</a></li>
        </ul>
    </div>
</div>
</template>
<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/user";
import { useRouter } from "vue-router";
import Notifier from '@/resources/modules/Notifier';

export default {
	name: "MenuApp",
	setup() {
		const authStore = useAuthStore();
		const user = computed(() => { return authStore.user; });
		const isAuthenticated = computed(() => { return authStore.isAuthenticated; });

		const router = useRouter();
		const { t } = useI18n();

		const logout = () => {
			authStore.logout();

			Notifier.success(t('nav.logout_success'),{
				timeout: 3000				
			});

			router.push({ name: "Home" });
		};


		return {
			t,
			user,
			logout,
			isAuthenticated
		};
	}
};
</script>
