import UIkit from "uikit";

export default class Notifier {
    /**
     * Notifying the user
     * @param {string} message 
     * @param {string} type 
     * @param {object} options UIkit notifications options
     */
    static notify(message, type = 'error', options = {}){
        if(!message){
            message = type == 'error' ? this.translator.t('errors.generic') : this.translator.t('request.success');
        }
        
        const setOptions = {
            message: message,
            status: (type == 'error' ? 'danger' : 'success'),
            pos: 'top-right',
            timeout: (type == 'error' ? 0 : 7000)
        }

        if(Object.keys(options).length){
            for(let o in options){
                setOptions[o] = options[o];
            }
        }
        UIkit.notification(setOptions);

    }
    

    /**
     * Showing error message
     * 
     * @param {string} message 
     * @param {object} options UIkit notifications options
     */
    static error(message, options = {}){
        this.notify(message, 'error', options);
    }


    /**
     * Showing success message
     * @param {string} message 
     * @param {object} options UIkit notifications options
     */
    static success(message, options = {}){
        this.notify(message, 'success', options);
    }
}