<template>
	<div class="login">
		<div class="uk-container uk-container-xsmall">
		<h1 class="uk-heading-divider uk-text-center page_title">
			{{ t("home.login") }}
		</h1>
		<form @submit.prevent="login" method="post">
			<div class="uk-margin">
			<label class="uk-form-label" for="username">{{
				t("home.email")
			}}</label>
			<div class="uk-form-controls">
				<input
				class="uk-input"
				id="username"
				type="text"
				:placeholder="t('home.email_placeholder')"
				v-model="email"
				required
				name="username"
				autocomplete="username"
				/>
			</div>
			</div>
			<div class="uk-margin">
			<label class="uk-form-label" for="password">{{
				t("home.password")
			}}</label>
			<div class="uk-form-controls">
				<input
				class="uk-input"
				id="password"
				type="password"
				:placeholder="t('home.password_placeholder')"
				v-model="password"
				required
				name="password"
				autocomplete="current-password"
				/>
			</div>
			</div>
			<div class="uk-margin-medium-top uk-grid uk-grid-large" uk-grid>
			<div class="uk-width-2-3@s">
				<p class="uk-text-small">
				{{ t("home.lost_password") }}
				<router-link :to="{ name: 'PasswordRecover' }">{{
					t("home.recover_password")
				}}</router-link>
				</p>
			</div>
			<div class="uk-width-1-3@s uk-text-right">
				<button class="uk-button uk-button-primary">
				{{ t("home.login") }}
				</button>
			</div>
			</div>
		</form>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/user";
import Notifier from "@/resources/modules/Notifier"

export default {
setup() {
    const authStore = useAuthStore();
    const email = ref("");
    const password = ref("");

    const { t } = useI18n();

    const login = async () => {
		try {
			return await authStore.login(email.value, password.value).then(() => {
				Notifier.success(t("home.login_success"),{timeout: 1200})
			}).catch((error) => {
				Notifier.error(error,{timeout: 8000})
			});
			// Reindirizza l'utente alla pagina di destinazione o alla home page
		} catch (error) {
			Notifier.error(error,{timeout: 8000})
		}
	};

	return {
		login,
		email,
		password,
		t,
		};
	}
};
</script>
