import { i18n } from '@/main'

const AppName = process.env.VUE_APP_NAME;

export default function populateMetaTags(to) {
    if (to.matched.some(record => record.meta.title)) {
        document.title = i18n.global.t(to.meta.title)+" | "+AppName;
    }

    if (to.matched.some(record => record.meta.metaTags)) {

        // Turn the meta tag definitions into actual elements in the head.
        to.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                const val = key == 'content' ? i18n.global.t(tagDef[key]) : tagDef[key];
                tag.setAttribute(key, val);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
    }
}
